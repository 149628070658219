<template>
  <nice-select
    ref="select"
    v-bind="$attrs"
    filterable
    remote
    :options="clientsOptions"
    :clearable="clearable"
    :multiple="multiple"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
    default-first-option
    :placeholder="$t('clientSelect.placeholder')"
    :remote-method="fetchClients"
    :loading="loading"
    :disabled="disabled"
    :size="size"
    data-testid="client-select"
  >
    <template #tags="{ $onRemoveValue, values }">
      <span v-for="clientId in values" :key="clientId">
        <el-tag>
          <span class="text-black">{{ cachedClientsMap.get(clientId)?.name }}</span>
          <i
            class="fal fa-times clear-tag text-slate-500 hover:text-slate-800 ml-2 cursor-pointer"
            @click.prevent="$onRemoveValue(clientId)"
          />
        </el-tag>
      </span>
    </template>
    <template #option="{ item }">
      <div :class="[{ archived: item.archived }, 'flex items-center py-2']">
        <fa-icon name="lock" class="mr-4" v-if="item.locked" />
        <div>
          <div class="font-semibold text-sm" :data-id="item.id">{{ item.name }}</div>
          <div class="light text-xs">{{ item.email }}</div>
        </div>
      </div>
    </template>
  </nice-select>
</template>

<script lang="ts" setup>
import { cacheClients, cachedClientsMap } from "@/config/remote-data-cache"
import { DetailClient } from "@/interfaces"
import useCore from "@/plugins/use-core"
import { computed, nextTick, onMounted, ref } from "vue"

type Client = Pick<DetailClient, "id" | "name" | "email" | "archived"> & {
  locked: boolean
}

type Props = {
  size?: string
  includeChildren?: boolean
  disableLocked?: boolean
  disabled?: boolean
  clearable?: boolean
  fetchType?: string
}

type PropsWithoutMultiple = {
  modelValue: string
  multiple?: false
} & Props

type PropsWithMultiple = {
  modelValue: string[]
  multiple?: true
} & Props

const props = withDefaults(defineProps<PropsWithMultiple | PropsWithoutMultiple>(), {
  modelValue: "",
  multiple: false,
  size: "default",
  includeChildren: true,
  disableLocked: false,
  disabled: false,
  clearable: true,
  fetchType: "",
})

const clients = ref<Client[]>([])

const loading = ref(false)
const select = ref()

const emit = defineEmits(["ready", "change", "update:modelValue"])

const clientsOptions = computed(() =>
  clients.value.map(item => ({
    ...item,
    disabled: !!(props.disableLocked && item.locked) || null,
  }))
)

const { axios, graphql } = useCore()

const fetchMethod = computed(() => {
  const type = `fetch${props.fetchType}`

  switch (type) {
    case "fetchClients":
      return fetchClients
    case "fetchCommissionSplitClients":
      return fetchCommissionSplitClients
    default:
      return defaultFetch
  }
})

const defaultFetch = async query => {
  const { data } = await axios.get(`/contacts/clients?q=${query}${props.includeChildren ? "&include_children=1" : ""}`)

  return data.data
}
const savedQuery = ref(null)
const fetchClients = async query => {
  if (query) savedQuery.value = query
  if (!query && savedQuery.value) return // to stop refetching after first value has been selected
  if (!savedQuery.value) {
    loading.value = false
    clients.value = []
    return
  }

  loading.value = true
  const data = await fetchMethod.value(savedQuery.value)
  clients.value = data
  cacheClients(data)
  loading.value = false
}

const fetchCommissionSplitClients = query => {
  return graphql(
    `
      query CommissionSplitClients {
        commissionSplitClients(query: $query) {
          id
          name
          email
          archived
          locked
        }
      }
    `,
    { query }
  )
    .then(({ commissionSplitClients }) => commissionSplitClients)
    .catch(axios.handleError)
}

defineExpose({
  focus: () => select.value?.focus(),
})

const prefill = () => {
  if (!props.modelValue) return

  const ids = ([] as string[]).concat(props.modelValue).join(",")

  if (!ids) {
    console.warn("No client ids provided to prefill")
    return
  }

  axios
    .get(`/contacts/clients?client_ids=${ids}`)
    .then(({ data }) => {
      clients.value = data.data
      cacheClients(data.data)
      emit("ready")
    })
    .catch(error => {
      console.error(error)
    })
}

onMounted(() => {
  nextTick(() => {
    prefill()
  })
})
</script>

<style scoped>
.client-select {
  height: initial;
  line-height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
