<template>
  <div>
    <div class="mainSearchForm">
      <input
        id="search"
        type="search"
        v-model="search"
        autocomplete="off"
        :placeholder="$t('header.searchForm.placeholder')"
        class="form-control"
        ref="search"
        data-testid="input-global_search"
        @focus="inputFocused = true"
        @blur="inputFocused = false"
        allow-override-keys
      />
      <fa-icon name="search" />
    </div>
    <div
      class="search-overlay"
      style="display: block"
      v-if="showOverlay && search"
      @mouseover="divFocused = true"
      @mouseleave="divFocused = false"
      @click="divFocused = false"
    >
      <div id="searchResults" class="view search-results" :class="loading && !hasResults ? 'loading' : ''">
        <p class="empty-message" v-if="!hasResults && !loading" style="display: block">
          {{ $t("header.searchForm.empty") }}
        </p>
        <div v-else-if="hasResults">
          <div class="px-3 mb-3 mt-2" v-if="filteredSearchFormList.length">
            <overlay-header
              :title="[
                filteredSearchFormList.length,
                $t('header.searchForm.suggSite'),
                $t('header.searchForm.suggSites'),
              ]"
            />

            <ul>
              <overlay-item
                v-for="(page, $index) in filteredSearchFormList.slice(0, 3)"
                :key="page.id"
                :active="$index + 1 == selectedRowIndex"
                :title="page.item.title"
                icon="file-alt"
                :url="page.item.href"
              />
            </ul>
          </div>
          <div class="px-3 mb-3" v-if="results.clients && results.clients.clients && results.clients.clients.length">
            <overlay-header
              :title="[results.clients.total_count, $t('header.searchForm.client'), $t('header.searchForm.clients')]"
              :link="`/contacts/clients?q=${search}`"
            />
            <ul>
              <overlay-item
                v-for="(client, $index) in results.clients.clients"
                :key="client.id"
                :active="filteredSearchFormList.slice(0, 3).length + $index + 1 == selectedRowIndex"
                :title="client.name"
                icon="address-card"
                :url="client.url"
              >
                <span class="text-gray-600 text-sm" v-if="client.parent_id">
                  ({{ $t("header.searchForm.isChild") }})
                </span>
                <fa-icon name="lock" class="ml-1 text-red-700" v-if="client.no_access" />
                <fa-icon name="unlock" class="ml-1" v-if="client.locked && !client.no_access" />
                <template v-slot:subtitle>
                  <div v-if="client.email" v-redacted>
                    <span class="text-gray-600 text-sm">{{ client.email }}</span>
                    <copy-to-clipboard :text="client.email" class="text-gray-600" />
                  </div>
                </template>
              </overlay-item>
            </ul>
          </div>
          <div
            class="px-3 mb-3"
            v-if="results.properties && results.properties.resources && results.properties.resources.length"
          >
            <overlay-header
              :title="[
                results.properties.total_count,
                $t('header.searchForm.property'),
                $t('header.searchForm.properties'),
              ]"
              :link="`/portfolio/properties?q=${search}`"
            />

            <ul>
              <overlay-item
                v-for="(property, $index) in results.properties.resources"
                :key="property.id"
                :active="
                  filteredSearchFormList.slice(0, 3).length + results.clients.clients.length + $index + 1 ==
                  selectedRowIndex
                "
                :sub-title="
                  $customFilters.numberToCurrency(
                    property.marketing_type == 'RENT' ? property.base_rent : property.price,
                    getCurrency(property)
                  )
                "
                :url="'/portfolio/properties/' + property.id"
              >
                <template v-slot:icon>
                  <div class="image">
                    <img :src="property.title_image" class="w-100" v-if="property.title_image" />
                    <img src="@/assets/images/placeholders/image.png" class="w-100" v-else />
                  </div>
                </template>
                <template v-slot:title>
                  <div>
                    <span class="font-semibold">
                      {{ property.project ? `${property.name} - ${property.project.name}` : property.name }}
                    </span>
                    <span class="text-purple-700" v-if="property.forks_count > 0">
                      <fa-icon name="code-branch" class="ml-1" />
                      <span class="text-sm">{{ property.forks_count }}</span>
                    </span>
                  </div>
                </template>
              </overlay-item>
            </ul>
          </div>
          <div class="px-3 mb-3" v-if="results.activities && results.activities.resources && results.activities.resources.length">
            <overlay-header
              :title="[
                results.activities.total_count,
                $t('header.searchForm.activity'),
                $t('header.searchForm.activities'),
              ]"
              :link="`/contacts/feed?q=${search}`"
            />
            <ul>
              <overlay-item
                v-for="(item, $index) in results.activities.resources"
                :key="item.id"
                :active="
                  filteredSearchFormList.slice(0, 3).length +
                    results.clients.clients.length +
                    results.properties.resources.length +
                    $index +
                    1 ==
                  selectedRowIndex
                "
                :title="item.title"
                :sub-title="$customFilters.date(item.date)"
                @click="openActivity(item)"
              >
                <template v-slot:icon>
                  <activities-icon class="text-lg fa-fw mr-2" :resource="item" :interactive="false" />
                </template>
              </overlay-item>
            </ul>
          </div>
          <div class="px-3 mb-3" v-if="results.projects && results.projects.length">
            <overlay-header
              :title="[results.projects.length, $t('header.searchForm.project'), $t('header.searchForm.projects')]"
              :link="`/portfolio/projects?q=${search}`"
            />

            <ul>
              <overlay-item
                v-for="(project, $index) in results.projects.slice(0, 2)"
                :key="project.id"
                :active="
                  filteredSearchFormList.slice(0, 3).length +
                    results.clients.clients.length +
                    results.properties.resources.length +
                    results.activities.resources.length +
                    $index +
                    1 ==
                  selectedRowIndex
                "
                :title="project.name"
                :sub-title="project.address"
                :url="`/portfolio/projects/${project.id}`"
              >
                <template v-slot:icon>
                  <div class="image">
                    <img :src="project.image" class="w-100" v-if="project.image" />
                    <img src="@/assets/images/placeholders/image.png" class="w-100" v-else />
                  </div>
                </template>
              </overlay-item>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce"
import eventBus from "@/config/event-bus"
import shortcuts from "@/utils/shortcuts"
import Fuse from "fuse.js"
import { getSearchFormList } from "@/utils/get-search-form-list"
import ActivitiesIcon from "@/components/activities/Icon.vue"
import OverlayHeader from "@/components/header/SearchFormHeader.vue"
import OverlayItem from "@/components/header/SearchFormItem.vue"

const SEARCH_DEBOUNCE_MS = 300

export default {
  components: { ActivitiesIcon, OverlayHeader, OverlayItem },
  data() {
    return {
      divFocused: false,
      inputFocused: false,
      search: "",
      results: undefined,
      loading: false,
      selectedRowIndex: 1,
    }
  },
  watch: {
    $route(to) {
      this.inputFocused = false
      this.divFocused = false
    },
    showOverlay(newValue) {
      if (!newValue) {
        this.unregisterBinds()
      } else {
        this.registerBinds()
      }
    },
    search: debounce(async function (value) {
      this.selectedRowIndex = 1
      if (!value) {
        this.results = undefined
        this.loading = false
        return
      }
      this.loading = true
      const types = ["clients", "properties", "activities", "projects"]
      const results = await Promise.all(
        types.map(type =>
          this.$axios.getWithCancel("/search", { params: { type, q: value }, abortKey: `/search?type=${type}` })
        )
      )
      const data = results.reduce((acc, { data }) => ({ ...acc, ...(data ?? {}) }), {})
      this.results = {
        ...data,
        properties: {
          ...data.properties,
          resources: data.properties.resources.map(p => ({
            ...p,
            project: this.$db.shopData.projects.find(a => a.id === p.project_id),
          })),
        },
      }
      this.loading = false
    }, SEARCH_DEBOUNCE_MS),
  },
  computed: {
    showOverlay() {
      return !!this.divFocused || !!this.inputFocused
    },
    hasResults() {
      return (
        this.results &&
        (this.filteredSearchFormList.length ||
          (this.results.clients && this.results.clients.clients && this.results.clients.clients.length) ||
          (this.results.properties && this.results.properties.resources && this.results.properties.resources.length) ||
          (this.results.activities && this.results.activities.resources.length) ||
          (this.results.projects && this.results.projects.length))
      )
    },
    flatResults() {
      return [].concat(
        (this.filteredSearchFormList || [])
          .slice(0, 3)
          .map(o => ({ ...o, action: () => Turbolinks.visit(o.item.href) })),
        (this.results.clients?.clients || []).map(o => ({ ...o, action: () => Turbolinks.visit(o.url) })),
        (this.results.properties?.resources || []).map(o => ({
          ...o,
          action: () => Turbolinks.visit(`/portfolio/properties/${o.id}`),
        })),
        (this.results.activities?.resources || []).map(o => ({ ...o, action: () => this.openActivity(o) })),
        (this.results.projects || []).slice(0, 2).map(o => ({
          ...o,
          action: () => Turbolinks.visit(`/portfolio/projects/${o.id}`),
        }))
      )
    },
    filteredSearchFormList() {
      const fuse = new Fuse(this.searchFormList, {
        keys: ["title"],
        threshold: 0.1,
      })
      return fuse.search(this.search)
    },
    searchFormList() {
      return getSearchFormList(this.$db, this.$t)
    },
  },
  methods: {
    getCurrency(resource) {
      return resource.currency || this.$db.shopData.isoCurrency
    },
    propertyCategory(property) {
      const marketingType = this.$db.shopData.propertyOptionsMapping.marketing_type[property.marketing_type]
      const rsType = this.$db.shopData.propertyOptionsMapping.rs_type[property.rs_type]
      return `${marketingType} > ${rsType}`
    },
    openActivity(resource) {
      eventBus.$emit("quick-view", {
        type: resource.conversation_type,
        mode: "view",
        id: resource.activatable_id,
      })
    },
    unregisterBinds() {
      shortcuts.deleteScope("search")
      shortcuts.unbind("up, down, enter, esc", "search")
    },
    registerBinds() {
      shortcuts.bind("up", "search", _ => {
        if (this.selectedRowIndex > 1) {
          this.selectedRowIndex--
        } else {
          this.selectedRowIndex = 1
        }
      })
      shortcuts.bind("down", "search", _ => {
        if (this.selectedRowIndex < this.flatResults.length) {
          this.selectedRowIndex = this.selectedRowIndex + 1
        }
      })
      shortcuts.bind("enter", "search", _ => {
        if (this.selectedRowIndex > -1) {
          this.flatResults[this.selectedRowIndex - 1].action?.()
          this.search = ""
          this.selectedRowIndex = 1
          this.$refs.search.blur()
        }
      })
      shortcuts.bind("esc", "search", _ => {
        this.search = ""
      })
      shortcuts.setScope("search")
    },
  },
  mounted() {
    const shortcut = /Mac/.test(navigator.platform) ? "command+k" : "ctrl+k"
    shortcuts.bind(shortcut, "all", () => this.$refs.search.focus())
  },
}
</script>
<style scoped>
.mainSearchForm {
  display: inline-block;
  position: relative;
  color: #777777;
  margin-right: 35px;
  font-weight: 500;
}
.mainSearchForm i {
  position: absolute;
  left: 13px;
  top: 8px;
}
.mainSearchForm input {
  border: 1px solid rgba(255, 255, 255, 0.33);
  padding: 3px 6px 3px 33px;
  border-radius: 40px;
  width: 200px;
  transition: width 150ms cubic-bezier(0.4, 0.2, 0.3, 1.35) !important;
  min-height: 30px;
  height: 30px;
}
.mainSearchForm input:focus {
  width: 300px;
  background: white;
}
.active {
  background-color: #00000015;
}
.search-image-li::after {
  clear: both;
}
.search-image-li::after,
.search-image-li::before {
  content: " ";
  display: table;
}

.search-image-li > a {
  display: flex;
}

.text-gray-600 text-sm {
  color: gray;
}

.hover-bg-color:hover {
  background-color: #00000015;
}
</style>

<style lang="scss" scoped>
.image {
  width: 25px;
  height: 25px;
  background: url("../../assets/images/property-placeholder.png") no-repeat;
  background-position: center;
  background-size: cover;
  padding-right: 0 !important;
  margin-right: 10px;
}

.heading-link {
  float: right;
}
</style>
